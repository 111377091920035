export default (link) => {
    const { public: runtimeConfig } = useRuntimeConfig();
    if (runtimeConfig.NODE_ENV === 'development') {
        console.warn('usePrepareStoryblokLink was called without a link object.');
    }

    if (!link) return '/';

    const { cached_url: cachedUrl } = link;

    let urlString = cachedUrl === 'home' ? '' : cachedUrl;
    urlString = urlString[0] === '/' ? urlString.substr(1) : urlString;

    const linkPrefix = link.linktype === 'email' ? 'mailto:' : '';
    const linkSuffix = link.anchor ? `#${link.anchor}` : '';

    return {
        link: `${linkPrefix}${urlString}${linkSuffix}`,
    };
};
