export default (links) => links.map((component) => {
    if (!component.link) {
        return {
            _uid: component._uid,
            text: component.label,
            link: component.mainlink,
            content_side: component.content_side,
            content_main: component.content_main,
        };
    }

    const { link } = component;

    return {
        ...usePrepareStoryblokLink(link),
        _uid: component._uid,
        text: component.label,
    };
});
