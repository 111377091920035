export default () => {
    onMounted(() => {
        useHead({
            script: [
                {
                    type: 'text/javascript',
                    src: 'https://legally-snippet.legal-cdn.com/v1/public/websites/qObgyQCK5Tmo4eOvMLyugBuUhDaEiV/cookie-banner/latest/blocker.js',
                },
                {
                    type: 'text/javascript',
                    src: 'https://legally-snippet.legal-cdn.com/v1/public/websites/qObgyQCK5Tmo4eOvMLyugBuUhDaEiV/cookie-banner/latest/banner.js',
                },
            ],
            link: [
                {
                    rel: 'preconnect',
                    href: 'https://legally-snippet.legal-cdn.com',
                },
                {
                    rel: 'preload',
                    href: 'https://legally-snippet.legal-cdn.com/v1/public/websites/qObgyQCK5Tmo4eOvMLyugBuUhDaEiV/cookie-banner/latest/blocker.js',
                    as: 'script',
                },
            ],
        });
    });
};
