export default defineStore('StoryStore', {
    state: () => ({
        currentStory: {},
        currentStoryId: '',
        locales: [],
    }),

    actions: {
        setCurrentStory(story) {
            this.currentStory = story;
        },

        setCurrentStoryId(id) {
            this.currentStoryId = id;
        },

        setLocales(locales) {
            this.locales = locales;
        },
    },

    getters: {
        getCurrentStory(state) {
            return state.currentStory;
        },

        getCurrentStoryId(state) {
            return state.currentStoryId;
        },

        getLocales(state) {
            return state.locales;
        },
    },
});
