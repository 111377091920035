<template>
  <UtilRendererIcon
    v-if="iconName"
    :key="uniqueKey"
    :name="iconName"
    class="ion-icon"
  />
</template>

<script setup>
const props = defineProps({
    iconName: {
        type: String,
        required: true,
        validator: (value) => value.length > 0,
    },

    colorSchema: {
        type: String,
        default: 'orange',
    },

    color: {
        type: String,
        default: '',
    },
});

const {
    color,
} = toRefs(props);

/*
  Rerender the asnyc child component when the icon name changes.
*/
const uniqueKey = ref(getUid());

watch(() => props.name, () => {
    uniqueKey.value = getUid();
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);
const color8 = computed(() => `var(--c-${colorTopLevel.value}-8)`);

const calculatorStore = useCalculatorStore();
const colorScheme = computed(() => calculatorStore.getColorScheme);

const colorIcon = `var(--c-${colorScheme.value}-4)`;
const colorSchema = computed(() => (props.colorSchema ? `var(--c-${props.colorSchema}-1)` : 'var(--c-orange-1)'));
const colorSchemaHover = computed(() => (props.colorSchema ? `var(--c-${props.colorSchema}-4)` : 'var(--c-orange-4)'));

const colorPalette = computed(() => (props.colorSchema === 'white' ? `var(--c-${props.colorSchema})` : `var(--c-${props.colorSchema}-4)`));
</script>

<style lang="scss">
.ion-icon {
    width: 20px;
    height: 20px;

    path {
        transition: fill 0.3s ease;
    }

    &.block-header__logo {
        width: 100px;
        height: 60px;

        path {
            fill: v-bind(color1);
        }

        .klik-letter {
            fill: v-bind(color8);
        }

        @include tablet {
            width: 108px;
            height: 42px;
        }

        @include mobile {
            width: 46px;
            height: 18px;
        }
    }

    .storyblok-teaser-box__link & {
        path {
            stroke: var(--c-white) !important;
        }
    }

    .block-hero & ,
    .storyblok-info-box &,
    .block-slider-image &,
    .block-slider-hero &,
    .atom-item-slider-hero & {
        path {
            fill: none;
            stroke: var(--c-white);
        }
    }

    .block-download-hub__filter-primary-program & {
        path {
            stroke: var(--department-color);
        }
    }

    &.block-header__icon  {
        width: 22px;
        height: 22px;
    }

    &.atom-item-slider-hero__icon {
        width: 22px;
        height: 22px;
    }

    &.atom-link__icon {
        path {
            stroke: v-bind(color4);
        }
    }

    &.block-breadcrumbs__icon {
        path {
            stroke: v-bind(color4);
        }
    }

    &.atom-input-multiselect__icon {
        path {
            stroke: v-bind(color4);
        }
    }

    &.atom-input-multiselect__icon-close {
        path {
            fill: v-bind(color4);
        }
    }

    .block-slider-hero__controls & {
        path {
            fill: none;
            stroke: var(--c-blue-4);
        }
    }

    .block-slider-image__slider & {
        path {
            stroke: v-bind(color1);
        }
    }

    .block-header__links &, .block-header__navigation & {
        width: 22px;
        height: 22px;

        path {
            fill: v-bind(color1);
        }

        .klik-letter {
            fill: v-bind(color8);
        }
    }

    .block-flyout__content-main & {
        width: 22px;
        height: 22px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-departments__department-label & {
        min-width: 20px;
        height: 20px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-international__region-label & {
        width: 20px;
        height: 20px;

        path {
            stroke: var(--c-white);
        }
    }

    .atom-input-radio-calc__info & {
        width: 22px;
        height: 22px;

        path, circle {
            stroke: v-bind(colorIcon);
        }

        .point {
            fill: v-bind(colorIcon);
        }
    }

    .block-calculation-item__info & {
        width: 22px;
        height: 22px;

        path, circle {
            stroke: v-bind(colorIcon);
        }

        .point {
            fill: v-bind(colorIcon);
        }
    }

    .block-flyout-mobile__bottom & {
        width: 40px;
        height: 40px;

        path, circle {
            stroke: v-bind(color1);
        }

        @include mobile {
            width: 22px;
            height: 22px;
        }
    }

    .block-nav-links-mobile__main-link & {
        width: 20px;
        height: 20px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-flyout-mobile__content & {
        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-departments-mobile {
        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-departments-mobile__department-label & {
        min-width: 20px;
        height: 20px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-departments-mobile__main-link & {
        width: 22px;
        height: 22px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-nav-international-mobile__main-link & {
        width: 22px;
        height: 22px;

        path {
            stroke: var(--c-white);
        }
    }

    .layout-person__content & {
        width: 23px;
        height: 23px;
    }

    .block-contribution-calculator__info & {
        width: 22px;
        height: 22px;

        path, circle {
            stroke: var(--c-white);
        }

        .point {
            fill: var(--c-white);
        }
    }

    .block-navigation-language__active-lang & {
        width: 20px;
        height: 20px;

        path {
            fill: none;
        }
    }

    &.block_teaser__icon {
        path {
            stroke: v-bind(color4);
        }
    }

    &.block-flyout-mobile__logo-home {
        width: 40px;
        height: 40px;

        path {
            stroke: v-bind(color1);
        }

        @include mobile{
            width: 22px;
            height: 22px;
        }
    }

    &.block-header__logo-home {
        path {
            fill: var(--c-white);
            stroke: v-bind(color1);
        }
    }

    &.block-navigation-language__icon {
        path {
            stroke: v-bind(color1);
        }
    }

    &.block-service-menu__item-icon {
        path {
            stroke: v-bind(color4);
        }

        circle {
            stroke: v-bind(color4);
        }
    }

    .block-overlay-person & {
        path {
            fill: v-bind(color1);
        }
    }

    .layout-news__hero-article-details-share & {
        width: 25px;
        height: 25px;
    }

    .layout-activity__hero-article-details-share & {
        width: 25px;
        height: 25px;
    }

    .block-map-international__slide-link & {
        path {
            stroke: var(--c-coral-1);
        }
    }

    .block-map-international__controls & {
        path {
            stroke: var(--c-coral-4);
        }
    }

    .block-map-international__close & {
        path {
            fill: var(--c-coral-4);
        }
    }

    .block-map-international__map-controls & {
        width: 36px;
        height: 36px;
    }

    .block-flyout-regions-mobile__title & {
        width: 22px;
        height: 22px;
    }

    .block-contribution-calculator__controls & {
        width: 22px;
        height: 22px;

        path {
            stroke: var(--c-white);
        }
    }

    .block-map-international__pinch-button & {
        width: 42px;
        height: 28px;

        path {
            stroke: var(--c-white);
        }

        .lock {
            fill: var(--c-white);
            stroke: none;
        }
    }

    .block-flyout-departments-mobile__title & {
        width: 22px;
        height: 22px;
    }

    .has-error & {
        path {
            fill: var(--c-error) !important;
        }
    }

    .block-download-hub & {
        min-width: 22px;
        height: 22px;
    }

    &.block-download-hub__filter-primary-country-icon {
        path {
            stroke: var(--c-coral-1);
        }

        .is-active & {
            path {
                stroke: var(--c-white);
            }
        }
    }

    .block-download-hub__search & {
        width: 22px;
        height: 22px;
    }

    .block-item-calculator__title & {
        min-width: 22px;
        height: 22px;
    }

    .block-contribution-calculator__header-controls & {
        width: 32px;
        height: 32px;

        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: var(--c-white);
        }

        @include mobile {
            width: 22px;
            height: 22px;
        }
    }

    .block-slider-enumeration__controls & {
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: v-bind(color4);
        }
    }

    &.block-download-hub__search-title-icon-magnifying {
        width: 29px;
        height: 29px;

        @include mobile {
            width: 23px;
            height: 23px;
        }
    }

    &.block-download-hub__search-title-icon {
        width: 22px;
        height: 22px;
    }

    &.block-download-hub__filter-primary-department-icon {
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: v-bind(color) !important;
        }
    }

    &.block-download-hub__filter-secondary-department-icon {
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: v-bind(color) !important;
        }

        .is-active & {
            path {
                stroke: var(--c-white) !important;
            }
        }
    }

    &.block-download-hub__filter-primary-program-icon {
        .is-active & {
            path {
                stroke: var(--c-white);
            }
        }
    }

    .block-download-hub__select & {
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: v-bind(color);
        }
    }

    .atom-item-download-card-mini & {
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: v-bind(color);
        }
    }

    .block-item-info-programm__link &  {
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: v-bind(colorSchema);
        }
        .is-active & {
            path {
                stroke: v-bind(colorSchemaHover);
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .block-calculation__error & {
        width: 22px;
        height: 22px;

        .point {
            fill: var(--c-error);
        }
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: var(--c-error);
        }
    }

    &.block-calculation__error-close {
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            fill: var(--c-error);
            stroke: none;
        }
    }

    &.block-flyout-regions__title-icon  {
        width: 36px;
        height: 36px;
    }

    &.block-flyout-departments__title-icon  {
        width: 36px;
        height: 36px;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .block-item-color__title & {
        width: 36px;
        height: 36px;

        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: v-bind(colorPalette);
        }

        @include mobile {
            width: 22px;
            height: 22px;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .block-item-color__title-closed & {
        width: 36px;
        height: 36px;

        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: var(--c-white);
        }

        @include mobile {
            width: 22px;
            height: 22px;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .block-item-color__controls & {
        width: 36px;
        height: 36px;

        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: var(--c-white);

            .is-dark & {
                stroke: var(--c-black);
            }
        }

        @include mobile {
            width: 22px;
            height: 22px;
        }
    }
    /* stylelint-disable-next-line no-descending-specificity */
    .block-item-color__content-details-left-wrapper-item-icon & {
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: v-bind(colorPalette);
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .block-header__navigation-login & {
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            fill: var(--c-white);
            stroke: v-bind(color4);
            transition: all 0.2s $EASE_IN_OUT--SINE;
        }

        circle {
            fill: var(--c-white);
            stroke: v-bind(color4);
            transition: all 0.2s $EASE_IN_OUT--SINE;
        }
    }

    .block-header__navigation-login:hover & {
        path {
            fill: v-bind(color4);
            stroke: var(--c-white);
        }

        circle {
            fill: v-bind(color4);
            stroke: var(--c-white);
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .block-header__navigation-login-mobile & {
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            fill: var(--c-white);
            stroke: v-bind(color4);
            transition: all 0.2s $EASE_IN_OUT--SINE;
        }

        /* stylelint-disable-next-line no-descending-specificity */
        circle {
            fill: var(--c-white);
            stroke: v-bind(color4);
            transition: all 0.2s $EASE_IN_OUT--SINE;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .block-teaser-news__controls & {
        width: 25px;
        height: 25px;

        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: v-bind(color1);
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .layout-news__controls & {
        width: 25px;
        height: 25px;

        /* stylelint-disable-next-line no-descending-specificity */
        path {
            stroke: v-bind(color1);
        }
    }
}
</style>
