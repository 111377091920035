import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowDownload, LazySvgoArrowUpRight, LazySvgoChevronDownThin, LazySvgoChevronDown, LazySvgoChevronLeftSkip, LazySvgoChevronLeftThin, LazySvgoChevronLeft, LazySvgoChevronRightSkip, LazySvgoChevronRightThin, LazySvgoChevronRight, LazySvgoChevronUpThin, LazySvgoChevronUp, LazySvgoClipboard, LazySvgoClose, LazySvgoContactPhone, LazySvgoContact, LazySvgoCopy, LazySvgoExternalFuelDispenser, LazySvgoExternalGear, LazySvgoExternalHouse, LazySvgoExternalLeaf, LazySvgoFacebook, LazySvgoHint, LazySvgoHome, LazySvgoHouse, LazySvgoInfo, LazySvgoInternalFuelDispenser, LazySvgoInternalGear, LazySvgoInternalHouse, LazySvgoInternalLeaf, LazySvgoLinkedin, LazySvgoLock, LazySvgoLogin, LazySvgoLogo, LazySvgoLogout, LazySvgoMenuBurger, LazySvgoMenu, LazySvgoMinus, LazySvgoMove, LazySvgoNews, LazySvgoPflichtfeld, LazySvgoPlus, LazySvgoPrint, LazySvgoSearch, LazySvgoShare, LazySvgoTwitter, LazySvgoUnternehmen, LazySvgoVideo, LazySvgoWebsite, LazySvgoYoutube, LazySvgoZoom } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowDownload", LazySvgoArrowDownload],
["SvgoArrowUpRight", LazySvgoArrowUpRight],
["SvgoChevronDownThin", LazySvgoChevronDownThin],
["SvgoChevronDown", LazySvgoChevronDown],
["SvgoChevronLeftSkip", LazySvgoChevronLeftSkip],
["SvgoChevronLeftThin", LazySvgoChevronLeftThin],
["SvgoChevronLeft", LazySvgoChevronLeft],
["SvgoChevronRightSkip", LazySvgoChevronRightSkip],
["SvgoChevronRightThin", LazySvgoChevronRightThin],
["SvgoChevronRight", LazySvgoChevronRight],
["SvgoChevronUpThin", LazySvgoChevronUpThin],
["SvgoChevronUp", LazySvgoChevronUp],
["SvgoClipboard", LazySvgoClipboard],
["SvgoClose", LazySvgoClose],
["SvgoContactPhone", LazySvgoContactPhone],
["SvgoContact", LazySvgoContact],
["SvgoCopy", LazySvgoCopy],
["SvgoExternalFuelDispenser", LazySvgoExternalFuelDispenser],
["SvgoExternalGear", LazySvgoExternalGear],
["SvgoExternalHouse", LazySvgoExternalHouse],
["SvgoExternalLeaf", LazySvgoExternalLeaf],
["SvgoFacebook", LazySvgoFacebook],
["SvgoHint", LazySvgoHint],
["SvgoHome", LazySvgoHome],
["SvgoHouse", LazySvgoHouse],
["SvgoInfo", LazySvgoInfo],
["SvgoInternalFuelDispenser", LazySvgoInternalFuelDispenser],
["SvgoInternalGear", LazySvgoInternalGear],
["SvgoInternalHouse", LazySvgoInternalHouse],
["SvgoInternalLeaf", LazySvgoInternalLeaf],
["SvgoLinkedin", LazySvgoLinkedin],
["SvgoLock", LazySvgoLock],
["SvgoLogin", LazySvgoLogin],
["SvgoLogo", LazySvgoLogo],
["SvgoLogout", LazySvgoLogout],
["SvgoMenuBurger", LazySvgoMenuBurger],
["SvgoMenu", LazySvgoMenu],
["SvgoMinus", LazySvgoMinus],
["SvgoMove", LazySvgoMove],
["SvgoNews", LazySvgoNews],
["SvgoPflichtfeld", LazySvgoPflichtfeld],
["SvgoPlus", LazySvgoPlus],
["SvgoPrint", LazySvgoPrint],
["SvgoSearch", LazySvgoSearch],
["SvgoShare", LazySvgoShare],
["SvgoTwitter", LazySvgoTwitter],
["SvgoUnternehmen", LazySvgoUnternehmen],
["SvgoVideo", LazySvgoVideo],
["SvgoWebsite", LazySvgoWebsite],
["SvgoYoutube", LazySvgoYoutube],
["SvgoZoom", LazySvgoZoom]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
