<template>
  <NuxtLayout
    :name="layout"
    :service-menu="serviceMenu"
    :newsletter-form="newsletterForm"
    :header-links="headerLinks"
    :footer="footer"
    :extra-data="storyContent.extraData"
    :first-published-at="storyContent.firstPublishedAt"
  >
    <NuxtPage
      :main-content="storyContent.mainContent ? storyContent.mainContent : secondaryPageContent"
      :rtl="isRtl"
      :class="{ isRtl: isRtl }"
    />
  </NuxtLayout>
</template>

<script setup>
/*
    Tracking
*/

const settingsStore = useSettingsStore();
// const trackingSettings = computed(() => settingsStore.getSetting('tracking'));
// const $gtag = inject('gtag');
const route = useRoute();

// const startTracking = () => {
//     setOptions({
//         config: { id: trackingSettings.value.analytics_id },
//     });

//     bootstrap().then(() => {
//         $gtag.pageview({ page_path: route.path });
//     });
// };

// startTracking();

/*
    Initialize translatable slugs
*/
const slugsStore = useSlugsStore();
await slugsStore.fetchSlugs();
slugsStore.setBreadcrumbs();

/*
  Initialize dependencies and variables
*/
const storyblokApi = useStoryblokApi();
const version = route.query._storyblok ? 'draft' : 'published';
const isInitialRequest = ref(true);
const layout = ref('default');

/*
  Get the current locale and language
*/
const { locale: currentLocale, defaultLocale } = useI18n();
const language = ref('');

/*
  Get the current path and remove the language prefix
*/
const replacedPath = ref('');
const path = ref('');

/*
  Prepare the meta data used for the request
*/
const prepareMetaData = () => {
    language.value = currentLocale.value === defaultLocale.value ? '' : currentLocale.value;
    replacedPath.value = route.path.replace(`/${language.value}`, '');

    const replaceSearch = route.path.length === currentLocale.value.length + 2
        ? `/${language.value}/` : `/${language.value}`;

    replacedPath.value = route.path.startsWith(replaceSearch) ? route.path.replace(replaceSearch, '') : route.path;
    path.value = replacedPath.value === '/' || replacedPath.value === '' ? '/home' : replacedPath.value;
};

prepareMetaData();

/*
  Watch the route path and remove the language prefix
*/
watch(() => route.path, (newValue, oldValue) => {
    prepareMetaData();

    if (newValue !== oldValue) {
        // $gtag.pageview({ page_path: newValue });
    }
});

/*
  Prepare the data fetching
*/
const { public: runtimeConfig } = useRuntimeConfig();
const { NODE_ENV } = runtimeConfig;

const promises = [
    useAsyncData(
        'storyblok-story-data',
        () => storyblokApi.get(
            `cdn/stories${path.value}`,
            {
                version: NODE_ENV === 'production' ? version : 'draft',
                language: language.value,
                resolve_relations: 'News.department,News.tags,News.author,News.type,Activity.department,Activity.tags,Activity.country,Activity.type',
            },
        ),
        {
            watch: [path, language],
        },
    ),
];

/*
  Fetch the settings data if it's the initial request
*/
if (isInitialRequest.value) {
    promises.push(settingsStore.fetch(language.value));
    isInitialRequest.value = false;
}

const [
    { data: storyData, error: storyError },
] = await Promise.all(promises);

if (storyError.value) {
    throw createError({
        statusCode: 404,
        message: 'Page not found',
        fatal: true,
    });
}

/*
  Watch the value of the error and throw a 404 error if true
*/
watch(() => storyError.value, (newValue) => {
    if (newValue) {
        throw createError({
            statusCode: 404,
            message: 'Page not found',
            fatal: true,
        });
    }
});

/*
  Set Layout and SEO
*/
const activityStore = useActivityStore();
const oldLayout = ref('');
const setLayout = () => {
    layout.value = storyData?.value?.data?.story?.content?.component?.replace('Page', '')?.toLowerCase();

    if (layout && (layout !== oldLayout.value)) {
        setPageLayout(layout);
        activityStore.setCurrentLayout(layout);
        oldLayout.value = layout;
    }
};

watch(() => layout.value, () => {
    activityStore.setCurrentLayout(layout);
});

const setSEO = () => {
    const SEOData = storyData?.value?.data?.story?.content?.seo;

    /*
        If the layout is news, use the news data for the SEO if no SEO data is set
    */
    if (layout.value === 'news') {
        const mappedSEOData = {
            title: SEOData?.title || storyData?.value?.data?.story?.content?.title || '',
            description: SEOData?.description || storyData?.value?.data?.story?.content?.lead,
            og_image: SEOData?.og_image
            || storyData?.value?.data?.story?.content?.image_video?.filename,
        };

        setData(mappedSEOData);
        return;
    }

    if (SEOData) {
        setData(SEOData);
    }
};

setLayout();
setSEO();

const storyStore = useStoryStore();
storyStore.setCurrentStory(storyData.value.data.story);

watch(() => storyData.value, () => {
    setLayout();
    setSEO();
});

/*
  Refresh the settings data if the language changes
*/
watch(() => language.value, (newValue) => {
    settingsStore.fetch(newValue);
});

/*
  If version is draft, load the bridge to support storybloks preview mode
*/
onMounted(() => {
    if (version === 'draft') {
        useStoryblokBridge(storyData?.value?.data?.story?.id, (evStory) => {
            storyData.value.data.story = evStory;
        });
    }
});

/*
  Computed data from api
*/
const storyContent = computed(() => {
    const sbStory = storyData?.value?.data?.story;
    const content = sbStory?.content;
    const currentPageSlugs = {};

    currentPageSlugs[defaultLocale] = sbStory.default_full_slug || sbStory.full_slug;

    if (sbStory.translated_slugs) {
        sbStory.translated_slugs.forEach((slug) => {
            currentPageSlugs[slug.lang] = slug.path;
        });
    }

    slugsStore.setCurrentPageSlugs(currentPageSlugs);

    if (!content) return null;

    const extraData = content;

    return {
        extraData,
        mainContent: content.main_content,
        secondaryPageContent: content.Content,
        layout: content.component.replace('Page', '').toLowerCase(),
        seo: content.seo,
        firstPublishedAt: sbStory.first_published_at,
    };
});

const serviceMenu = computed(() => settingsStore.getSetting(
    'service',
));

const newsletterForm = computed(() => settingsStore.getSetting(
    'newsletter',
));

const isRtl = computed(() => storyData?.value?.data?.story?.content?.rtl);

const headerLinks = computed(() => {
    const links = settingsStore.getSetting(
        'navigation',
        'header_navigation',
    );

    return useMapStoryblokLinks(links);
});

const footer = computed(() => settingsStore.getSetting(
    'footer',
));

/*
    Colorschema
*/
const allSlugs = slugsStore.getSlugs;
const activeRoute = computed(() => route.path);
const { locale } = useI18n();

// get slug with the exact path
const getTranslatedSlugs = (string) => {
    const slug = allSlugs.find((item) => item.de === string);

    if (!slug) {
        return null;
    }

    // return slug in current language
    return slug[locale.value];
};

const slugsMappingColor = settingsStore.getSetting(
    'mappingcolors',
    'slugs',
);

const slugActivities = settingsStore.getSetting(
    'navigation',
    'slug_activities',
);

const colorSchema = ref('petrol');

const checkRoute = async () => {
    try {
        slugsMappingColor.forEach((item) => {
            if (activeRoute.value.includes(getTranslatedSlugs(item?.slug))) {
                colorSchema.value = item.color;
            }
        });

        // if home set color to petrol
        if (activeRoute.value === `/${locale.value}` || activeRoute.value === '/') {
            colorSchema.value = 'petrol';
        }

        storyStore.setCurrentStoryId(storyData.value.data.story.id);

        // if activities not in route reset activity filters
        if (!activeRoute.value.includes(slugActivities)) {
            activityStore.resetStates();
        }
    } catch (e) {
        useSentryError(e);
    }
};

// initial
checkRoute();
provide('colorSchema', colorSchema);

const filterStore = useFilterStore();

watch(() => activeRoute.value, () => {
    checkRoute();

    // reset filters after route change
    if (!activeRoute.value.includes(slugActivities)) {
        filterStore.resetPreFilteredCountry();
    }
});

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.getIsAuthenticated);

const checkIfAuthPage = () => {
    // Set if it is  an auth page
    authStore.setIsAuthPage(storyData.value.data.story.content.isAuth);

    if (storyData.value.data.story.content.isAuth && !isAuthenticated.value) {
        navigateTo('/');
    }
};

checkIfAuthPage();

watch(() => storyData.value, () => {
    checkRoute();
    checkIfAuthPage();
});

// Legally OK Cookie Banner
useLegallyCookieBanner();

/*
    Languagesettings for pages and branches
*/
const languagesPage = computed(() => storyData?.value?.data?.story?.content?.languagesPage);
const locales = ref([]);
const currentBranch = ref(null);

/* Settings for branchlanguages */
const langSettings = computed(() => settingsStore.getSetting(
    'language',
));

const branches = computed(() => langSettings.value.branches);

const setLanguages = () => {
    if (branches?.value && branches?.value.length > 0) {
        branches.value.forEach((branch) => {
            const newPath = path.value.split('/').slice(0, 2).join('/');
            if (newPath.includes(branch.path)) {
                currentBranch.value = branch;
            }
        });
    }

    if (languagesPage.value && languagesPage.value.length > 0) {
        locales.value = languagesPage.value;
    } else if (currentBranch.value !== null) {
        locales.value = currentBranch.value.languages;
    } else {
        locales.value = ['de', 'it', 'fr'];
    }

    storyStore.setLocales(locales.value);
};

setLanguages();

watchEffect(() => {
    setLanguages();
});
/*
    Save the scroll position and scroll there on language change
*/
const scrollPosition = ref(0);

onMounted(() => {
    window.addEventListener('scroll', () => {
        scrollPosition.value = window.scrollY;
    });
});

const scrollTo = (position) => {
    gsapScrollTo(window, {
        scrollTo: {
            y: position,
        },
    });
};

// watch lang change
watch(() => currentLocale.value, () => {
    scrollTo(scrollPosition.value);
});
</script>

<style lang="scss">
.main-content {
    background: var(--c-background);
    @include fluid('padding-bottom', 30px, 60px);
}

.page-enter-active,
.page-leave-active {
    transition: all 0.2s;
}

.page-leave-to {
    opacity: 0;
}

.page-enter-from {
    opacity: 0;
}
.isRtl {
    direction: rtl;
}
</style>
