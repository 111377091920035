const title = ref('');

export const setData = (seoToRender) => {
    title.value = seoToRender?.title || '';

    useHead({
        title: `${seoToRender?.title || ''}`,
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: seoToRender?.description || '',
            },
            {
                hid: 'og-title',
                property: 'og:title',
                content: seoToRender?.og_title || '',
            },
            {
                hid: 'og-description',
                property: 'og:description',
                content: seoToRender?.og_description || '',
            },
            {
                hid: 'og-image',
                property: 'og:image',
                content: seoToRender?.og_image || '',
            },
            {
                hid: 'twitter-card',
                property: 'twitter:card',
                content: 'summary',
            },
            {
                hid: 'twitter-title',
                property: 'twitter:title',
                content: seoToRender?.twitter_title || '',
            },
            {
                hid: 'twitter-description',
                property: 'twitter:description',
                content: seoToRender?.twitter_description || '',
            },
            {
                hid: 'twitter-image',
                property: 'twitter:image',
                content: seoToRender?.twitter_image || '',
            },
        ],
    });
};

export const getTitle = () => title.value;
