/*
    This store is used to fetch all slugs from Storyblok
    and to map them to the correct language.
    This is needed as a workaround because nuxt i18n for nuxt 3 has
    no full support for complete dynamic routes yet.
*/
export default defineStore('SlugsStore', {
    state: () => ({
        slugs: [],
        currentPageSlugs: {},
        breadcrumbs: [],
        links: [],
    }),
    actions: {
        /*
            Sets the page slugs for the current page
            in each language
        */
        setCurrentPageSlugs(slugs) {
            this.currentPageSlugs = slugs;
        },
        async fetchSlugs() {
            const storyblokApi = useStoryblokApi();
            /*
                Fetch all slugs from Storyblok
            */
            const { data } = await storyblokApi.get('cdn/links', {
                version: 'draft',
                /* You may need to adjust this value based on the amount of pages in your project */
                per_page: 10000,
            });

            const { links } = data;
            this.links = links;

            /*
                Map all slugs to an array of objects
                with the following structure:
                {
                    [default language]: 'home',
                    en: 'home',
                    de: 'home',
                    ... other languages (if available by alternates)
                }
            */
            const mappedSlugs = Object.values(links).map((link) => {
                const mappedLink = link;
                const { alternates } = link;
                const mergedAlternates = {};

                /* Merge all alternates into one object */
                if (alternates) {
                    alternates.forEach((alternate) => {
                        mergedAlternates[alternate.lang] = alternate.translated_slug;
                    });
                }

                /* Return the mapped link */
                return {
                    de: mappedLink?.slug,
                    ...mergedAlternates,
                };
            });
            this.slugs = mappedSlugs;
        },
        setBreadcrumbs() {
            /*
                Map all slugs in links to an array of objects
                with the following structure:
                breadcrumbs: [
                    {
                        slug: '',
                        names: {
                            de: '',
                            fr: '',
                            it: '',
                            en: '',
                        }
                ]
            */

            const breadcrumbs = Object.values(this.links).map((link) => {
                const mappedLink = link;
                const { alternates } = link;
                const mergedAlternates = {};
                const mergedAlternateLinks = {};

                /* Merge all alternates into one object */
                if (alternates) {
                    alternates.forEach((alternate) => {
                        mergedAlternates[alternate.lang] = alternate.name;
                        mergedAlternateLinks[alternate.lang] = alternate.path;
                    });
                }

                /* Return the mapped link */
                return {
                    slugs: {
                        de: mappedLink?.slug,
                        ...mergedAlternateLinks,
                    },
                    names: {
                        de: mappedLink.name,
                        ...mergedAlternates,
                    },
                };
            });

            this.breadcrumbs = breadcrumbs;
        },
    },
    getters: {
        getTranslatedSlug: (state) => (slug, specificLanguage) => {
            if (!slug) return '/';
            const searchSlug = slug === '/' ? 'home' : slug;
            const { locales, locale } = useI18n();
            const { slugs } = state;
            const searchLang = specificLanguage || locale.value;
            if (!slugs) return '/';

            /*
                Strip out all possible locales from slug
            */
            const strippedSlug = locales?.value.reduce((acc, lang) => {
                const regex = new RegExp(`^/${lang.iso}/`);
                return acc.replace(regex, '');
            }, searchSlug) || '';
            /*
                First try to find a slug that matches the current or passed in language
            */
            let result = slugs.find((item) => item[searchLang] === strippedSlug);
            /*
                If no slug was found, try to find a slug that matches in any language
            */
            if (!result) {
                result = slugs.find((item) => {
                    const translatedSlugs = Object.keys(item);
                    return translatedSlugs.some((lang) => item[lang] === strippedSlug);
                });
            }
            /*
                If no slug was found, return an empty string
            */
            if (!result) return '/';
            /*
                If the first part of the slug is 'home', return '/'
            */
            const splittedSlug = result[searchLang]?.split('/');
            if (splittedSlug?.length > 0 && splittedSlug[0] === 'home') return '/';
            /*
                Return the slug for the current language
            */
            // eslint-disable-next-line no-nested-ternary
            return result ? result[searchLang] !== undefined ? `/${result[searchLang]}` : `/${result.de}` : '/';
        },
        getSlugs: (state) => state.slugs,
        switchLocalePath: (state) => (locale) => {
            const runtimeConfig = useRuntimeConfig();
            const hasTranslatedSlugs = runtimeConfig.public.TRANSLATED_SLUGS;

            /*
                Prepare the prefix for the locale and the slug
            */
            const { locale: currentLocale, defaultLocale } = useI18n();
            // eslint-disable-next-line no-nested-ternary
            const slug = hasTranslatedSlugs === 'false'
                ? state.currentPageSlugs.de
                : state.currentPageSlugs[locale] === 'home' ? '' : state.currentPageSlugs[locale];

            const prefix = ref('');
            prefix.value = locale === defaultLocale ? '' : `/${locale}`;

            /*
                If translated slugs is false, return the slug without the locale prefix
            */
            if (!hasTranslatedSlugs || hasTranslatedSlugs === 'false') {
                // if currentLocale is in slug, remove /currentLocale except for defaultLocale
                const replacedSlug = slug.replace(`${currentLocale.value}/`, '');

                prefix.value = locale === defaultLocale ? '' : `/${locale}`;
                return locale === defaultLocale ? `/${replacedSlug}` : `${prefix.value}/${replacedSlug}`;
            }

            /*
                only return the locale prefix if its home
            */
            if (!slug) {
                prefix.value = locale === defaultLocale ? '/' : `/${locale}`;
                return `${prefix.value}`;
            }

            /*
                return the locale prefix and the slug
            */
            return `${prefix.value}/${slug}`;
        },
        getBreadcrumbs: (state) => {
            const { locale } = useI18n();
            const { breadcrumbs } = state;
            const currentSlug = ref(state.currentPageSlugs[locale.value]
                ? state.currentPageSlugs[locale.value] : state.currentPageSlugs.de);

            // remove the last slash from currentSlug
            if (currentSlug.value?.endsWith('/')) {
                currentSlug.value = currentSlug.value.slice(0, -1);
            }

            const breadCrumbs = [];

            breadcrumbs.forEach((breadcrumb) => {
                // eslint-disable-next-line no-param-reassign
                breadcrumb.slug = breadcrumb.slugs[locale.value];
            });

            let currentBreadcrumb = breadcrumbs.find(
                (breadcrumb) => breadcrumb?.slug === currentSlug.value,
            );

            if (!currentBreadcrumb) {
                const splittedSlug = currentSlug.value?.split('/');
                splittedSlug.pop();
                currentBreadcrumb = breadcrumbs.find((breadcrumb) => breadcrumb?.slug === splittedSlug.join('/'));
            }

            while (currentBreadcrumb) {
                breadCrumbs.unshift(currentBreadcrumb);
                const splittedSlug = currentBreadcrumb.slug?.split('/');
                splittedSlug.pop();
                currentBreadcrumb = breadcrumbs.find((breadcrumb) => breadcrumb?.slug === splittedSlug.join('/'));
            }

            // if the last breadcrumb has a / at the end, remove the one before
            if (breadCrumbs[breadCrumbs.length - 1]?.slug.endsWith('/')) {
                breadCrumbs.splice(breadCrumbs.length - 2, 1);
            }

            return breadCrumbs;
        },

    },
});

export function switchLocalePath(state) {
    return (locale) => {
        const { defaultLocale } = useI18n();
        const slug = state.currentPageSlugs[locale] === 'home' ? '' : state.currentPageSlugs[locale];

        const prefix = ref('');
        prefix.value = locale === defaultLocale ? '' : `/${locale}`;

        if (!slug) {
            prefix.value = locale === defaultLocale ? '/' : `/${locale}`;
            return `${prefix.value}`;
        }

        return `${prefix.value}/${slug}`;
    };
}
